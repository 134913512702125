import React from 'react'
import './Skills.css'

function Skills(props) {
  return (
    <div className='Skills'>
      <header className='SkillsTitle BigText'>Skills</header>
      <div className='SkillsList MedText'>
        <p>
          <span className='Bold MedText'>Programming:</span> Python, Java,
          Javascript, C/C++/C#, HTML/CSS
        </p>
        <p>
          <span className='Bold MedText'>Tools & Frameworks:</span> Git, MatLab,
          React/React Native, Jira, GraphQL, Tensorflow, SQL, MongoDB
        </p>
      </div>
    </div>
  )
}

export default Skills
