import React from 'react'
import './Experience.css'
const ExperienceEntry = (props) => {
  return (
    <div className='ExperienceEntry'>
      <h1 className='Bold BigText'>
        {props.company + (props.link ? ' ~ ' : '')}
        <a target='blank' className='Link MedText' href={props.link}>
          {props.link}
        </a>
      </h1>

      <header className='Bold MedText'>{props.position}</header>
      <p className='DateLoc MedText'>{props.dateloc}</p>
      <ul>
        {props.points.map((point) => (
          <li className='MedText'>{point}</li>
        ))}
      </ul>
    </div>
  )
}

function Experience() {
  return (
    <div className='Experience'>
      <ExperienceEntry
        company='Ardent Labs'
        link='https://solve.ardentlabs.io/'
        position='Full Stack Software Engineer'
        dateloc='Irvine, California - February 2019 to PRESENT '
        points={[
          'Leading the development of Ardent Solve, a web application which acts as a teaching tools for students. Currently serving 2000+ active students (Node.js, React, GraphQL).',
          'Maintain GraphQL API and RethinkDB database for 5 internal mobile and web applications.',
          'Implemented a point currency system in our API; utilizes server hook to automatically update point values.',
          'Patched security flaws in the backend by enforcing token authentication.',
          'Optimized performance of the server through server-side caching and request throttling.',
        ]}
      />
      <ExperienceEntry
        company='Ardent Academy'
        position='Teacher/Mentor'
        dateloc='Irvine, California - March 2019 to September 2019 '
        points={[
          'Designed the curriculum for a course on machine learning which incorporates Python libaries such as TensorFlow, Numpy, and Pandas.',
          'Taught machine learning concepts and procedures to middle and high school students.',
          'Provided technical support for students competing in science fairs.',
        ]}
      />
      <ExperienceEntry
        company='TCI/SPX Coporation'
        position='Software Engineering Intern'
        dateloc='Fremont, California - June 2018 to September 2018'
        points={[
          'Designed and developed a regression testing pipeline for company products.',
          'Built a local database for storing test data using Microsoft Access.',
          'Created scripts which parses through test reports and migrates relevant data into their databases. (C#, regex)',
          'Developed a Windows application which allowed users to control and monitor tesitng. (.NET)',
        ]}
      />
    </div>
  )
}

export default Experience
