import React from 'react'
import './Header.css'

function Header(props) {
  const { minimized } = props
  return (
    <div className={minimized ? 'Header headerSmall' : 'Header headerBig'}>
      <div className="Intro">
        <header className="Title">Brandon Luong</header>
        {!minimized && <p className="Description">Software Engineer</p>}
      </div>
    </div>
  )
}

export default Header
