import React from 'react'
import './Button.css'
import Resume from './../files/Resume_10_16_20.pdf'

function ResumeButton(props) {
  return (
    <button
      className='Button Resume'
      onClick={() => {
        window.open(Resume)
      }}
    >
      <svg className='Icon' viewBox='0 0 512 512'>
        <g>
          <g>
            <path d='M196,90c-16.538,0-31,13.462-31,30c0,16.538,14.462,30,31,30c16.538,0,31-13.462,31-30C227,103.462,212.538,90,196,90z' />
          </g>
        </g>
        <g>
          <g>
            <path d='M85.605,13.184L13.184,85.605c-1.359,1.359-2.45,2.9-3.611,4.395H90V9.571C88.506,10.732,86.964,11.825,85.605,13.184z' />
          </g>
        </g>
        <g>
          <g>
            <path d='M196,180c-41.353,0-76,35.647-76,77v15h152v-15C272,215.647,237.353,180,196,180z' />
          </g>
        </g>
        <g>
          <g>
            <path d='M377,0H120v105c0,8.291-6.709,15-15,15H0v347c0,8.291,6.709,15,15,15h279.853c-11.757-17.525-19.252-38.009-21.718-60H105    c-8.291,0-15-6.709-15-15c0-8.291,6.709-15,15-15h168.419c1.174-10.4,3.272-20.471,6.693-30H105c-8.291,0-15-6.709-15-15    c0-8.291,6.709-15,15-15h190.48c21.661-31.74,56.336-53.963,96.52-58.484V15C392,6.709,385.291,0,377,0z M302,287    c0,8.291-6.709,15-15,15H105c-8.291,0-15-6.709-15-15v-30c0-42.017,24.976-80.089,60.72-96.858    C141.053,149.487,135,135.489,135,120c0-33.091,27.909-60,61-60s61,26.909,61,60c0,15.489-6.053,29.487-15.72,40.142    C277.024,176.911,302,214.983,302,257V287z' />
          </g>
        </g>
        <g>
          <g>
            <path d='M407,302c-57.99,0-105,47.01-105,105s47.01,105,105,105s105-47.01,105-105S464.99,302,407,302z M462.605,387.605l-60,60    c-2.93,2.93-6.768,4.395-10.605,4.395c-3.837,0-7.676-1.465-10.605-4.395l-30-30c-5.859-5.859-5.859-15.352,0-21.211    s15.352-5.859,21.211,0L392,415.789l49.395-49.395c5.859-5.859,15.352-5.859,21.211,0S468.465,381.746,462.605,387.605z' />
          </g>
        </g>
      </svg>
      <p className='SmallText SocialsText'>Resume</p>
    </button>
  )
}

export default ResumeButton
