import React, { useState } from 'react'
import './NavBar.css'
import { withRouter } from 'react-router-dom'

function NavBar(props) {
  const currentTab = props.location.pathname.substring(1)
  props.setMinimized(currentTab === 'projects' || currentTab === 'experience')

  const [selected, setSelected] = useState(currentTab)
  if (currentTab !== selected) setSelected(currentTab)
  function NavButton(props) {
    return (
      <button
        className={props.selected ? 'NavButton selected' : 'NavButton'}
        onClick={() => {
          props.history.push(`/${props.extension}`)
          setSelected(props.extension)
        }}
      >
        <p className="MedText">{props.title}</p>
      </button>
    )
  }
  return (
    <div className="NavBar">
      <div className="Links">
        <NavButton
          history={props.history}
          title="Home"
          extension=""
          selected={selected === ''}
        />
        <NavButton
          history={props.history}
          title="Projects"
          extension="projects"
          selected={selected === 'projects'}
        />
        <NavButton
          history={props.history}
          title="Experience"
          extension="experience"
          selected={selected === 'experience'}
        />
        <NavButton
          history={props.history}
          title="Contact Me"
          extension="contact"
          selected={selected === 'contact'}
        />
      </div>
    </div>
  )
}

export default withRouter(NavBar)
