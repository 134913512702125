import React from 'react'
import './Contact.css'
import EmailButton from './../components/EmailButton'
import GithubButton from './../components/GithubButton'
import LinkedinButton from './../components/LinkedinButton'
import ResumeButton from './../components/ResumeButton'
function Contact() {
  return (
    <div className="Contact">
      <header className="MedText">
        You can reach me through any of my socials!
      </header>
      <div className="Socials">
        <EmailButton emailAddress="btluong1@gmail.com" />
        <GithubButton username="bdon1990x" />
        <LinkedinButton link="bdonluong" />
        <ResumeButton />
      </div>
    </div>
  )
}

export default Contact
