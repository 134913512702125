import React, { useState } from 'react'
import './App.css'
import Header from './screens/Header'
import Profile from './screens/Profile'
import Projects from './screens/Projects'
import Contact from './screens/Contact'
import Skills from './screens/Skills'
import Experience from './screens/Experience'
import NavBar from './components/NavBar'
import { HashRouter, Switch, Route } from 'react-router-dom'

function App() {
  const [minimized, setMinimized] = useState(false)

  return (
    <HashRouter>
      <div className='App'>
        <Header minimized={minimized} />
        <NavBar setMinimized={setMinimized} />
        <Switch>
          <Route path='/contact'>
            <Contact />
          </Route>
          <Route path='/experience'>
            <Experience />
          </Route>
          <Route path='/projects'>
            <Projects />
          </Route>
          <Route path=''>
            <Profile />
            <Skills />
          </Route>
        </Switch>
        <link
          href='https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap'
          rel='stylesheet'
        />
      </div>
    </HashRouter>
  )
}

export default App
