import './Projects.css'
import React from 'react'
import Alligator from './../images/alligator.png'
import Snake from './../images/Snake.png'
import Chair from './../images/furniture_generation.png'
import GithubButton from './../components/GithubButton'

const ProjectEntry = (props) => {
  return (
    <div className='ProjectEntry'>
      <div className='ProjectTitle'>
        <header className='BigText Bold'>{props.title}</header>
        {props.project !== undefined && props.project !== null && (
          <GithubButton username='bdon1990x' project={props.project} />
        )}
      </div>

      <div className='TextImage'>
        <p className='Bold MedText'>{props.date}</p>
        {props.text1 && <p className='MedText'>{props.text1}</p>}
        <p className='MedText'>{props.text2}</p>

        {props.Image && (
          <img className={props.ImageStyle} src={props.Image} alt='Project' />
        )}
      </div>
    </div>
  )
}

function Projects(props) {
  return (
    <div className='Projects'>
      <ProjectEntry
        title='WebGL - Ball Simulator'
        project='WebGL_Ball_Simulator'
        date='July 2020'
        text1='Mobile puzzle game for Android'
        text2='Simulation of a ball using Javascript and WebGL. The program implements a realistic physics engine which emulates ball movements and collisions.'
      />
      <ProjectEntry
        title='Orbyss - Mobile Puzzle Game App for Android'
        project='Orbyss'
        date='Mar 2020  -  PRESENT'
        text1='Mobile puzzle game for Android'
        text2='Orbyss is a 2D puzzle game based on the popular mobile game Puzzles and Dragon. The frontend of the application was created using Node.js and React Native.
               The backend consists of a mongoDB database with REST API.
              The database is used to store game data, such as player scores and credentials. The REST API was created using Express and Mongoose.'
      />
      <ProjectEntry
        title='Minecraft Furniture Generator'
        project='minecraft-alaina'
        date='Nov 2020 - Jan 2020'
        Image={Chair}
        ImageStyle='Chair Shadow'
        text1='Trained an generative adversarial neural network(GAN) to generate furniture in Minecraft.'
        text2='The AI was trained on a datset of 1000+ 3D meshes of IKEA furniture, classified by the type of furniture.
              The first step was convert the 3D models from meshes to voxels. This is allows the model to render in Minecraft.
              After all of the data had been converted to meshes, the initial GAN models were created and training began.
              The training algorithm used was stochastic gradient descent. The performance of the
              GAN was measured using wasserstein loss. Based on the results, the GAN hyper parameters were tweaked accordingly until satisfactory
              results were achieved.'
      />
      <ProjectEntry
        title='Baroque Music Generation AI'
        date='Spring 2019'
        text1='Taught an AI to compose baroque music through unsupervised learning'
        text2="The dataset consisted of 100 songs by Johann Sebastian Bach in MIDI file format.
               The first step was to process the MIDI files and encode the notes into text.
               The reason for this was to convert the data into a form that could be inputted
               into our training model. Next, The song text was then inputted into a LSTM neural
               network, and trained for 100 iterations. After training the AI was able to reproduce
               basic chord progressions, and melodies found in Bach's music."
      />
      <ProjectEntry
        title='3D Model Reconstruction of an Alligator'
        date='Spring 2019'
        text1='Reconstructed a 3D Model of an alligator using structured-light
               3D scanning.'
        text2='First, reference images were obtained by projecting structured
                light patterns onto a model of an alligator. A set of stereo
                cameras were used to take pictures of the alligator from different
                angles. Using epipolar geometry, 3D points were derived from the
                the references images. The points were converted into a 3D, triangular mesh and
                were then cleaned through manual pruning and a gaussian smoothing
                algorithms. Lastly, the reference images were sampled
                for textures of the alligator, and then the textures more mapped
                onto the model.'
        Image={Alligator}
        ImageStyle='Alligator Shadow'
      />
      <ProjectEntry
        title='Snake AI'
        date='Winter 2018'
        text1='Used Deep Q-learning and a genetic algorithm to create an
               AI for the game snake.'
        text2='The AI implements a feed-forward multilayer perceptron. The
               perceptron was trained using a genetic algorithm
               which runs 2000 iterations of the game concurrently. At the end
               of each generation the most fit snake of that generation is used
               as a base for generating snakes of the next generation. After
               many generations a smart snake emerged, which was able to play
               the game to exceptional levels.'
        Image={Snake}
        ImageStyle='Snake Shadow'
      />
    </div>
  )
}

export default Projects
