import React from 'react'
import './Profile.css'
import EmailButton from '../components/EmailButton'
import GithubButton from '../components/GithubButton'
import LinkedinButton from '../components/LinkedinButton'
import profileImg from '../images/brandon.png'
import ResumeButton from '../components/ResumeButton'
function Profile() {
  return (
    <div className='Profile'>
      <img className='profileImg Shadow' src={profileImg} alt='Profile' />

      <div className='Description'>
        <h3 className='AboutMe BigText'>About Me</h3>
        <p className='MedText Indent'>
          Hello there! I am Brandon Luong, a recent graduate of the University
          of California, Irvine. I currently work as a fullstack developer at
          Ardent Labs, where I lead the development of a website that serves as
          a teaching tool for young students.
        </p>
        <p className='MedText Indent'>
          In my free time, I enjoy exploring different areas of computer
          science. Some of my recent interest include machine learning, computer
          visions, and computer graphics.
        </p>
      </div>
      <div className='Socials'>
        <EmailButton emailAddress='btluong1@gmail.com' />
        <GithubButton username='bdon1990x' />
        <LinkedinButton link='bdonluong' />
        <ResumeButton />
      </div>
    </div>
  )
}

export default Profile
